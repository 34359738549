.state {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
  }
  
  .state-initializing {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fce7c6;
    color: #4c3726;
  }
  
  .state-pending {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fce7c6;
    color: #4c3726;
  }
  
  .state-scheduled {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #e5fc7d;
    color: #21304c;
  }
  
  .state-paused {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fce7c6;
    color: #4c3726;
  }
  
  .state-completed_with_fail {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fcd1d0;
    color: #4c2120;
  }
  
  .state-running {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #c1f4fc;
    color: #21444c;
  }
  
  .state-cancelled {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fcd1d0;
    color: #4c2120;
  }
  
  .state-completed {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #d0f0c0;
    color: #405020;
  }
  
  .state-failed {
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1rem;
    background-color: #fcd1d0;
    color: #4c2120;
  }
  