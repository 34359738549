.confined {
    margin-left: auto;
    margin-right: auto;
    max-width: 1150px;
}
.link span.no-underline {
    text-decoration: underline;
  }
.panel {
    margin-bottom: 2.5rem;
    padding: 2rem;
    border-radius: 0.375rem;
    border-width: 4px;
    --tw-border-opacity: 1;
    border-color: rgb(247 245 242 / var(--tw-border-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 21 18 / var(--tw-bg-opacity));
}

.justify-between {
    justify-content: space-between;
}

.flex {
    display: flex;
}

.font-bold {
    font-weight: 700;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(127 123 120 / var(--tw-text-opacity));
}

.link-simple {
    font-weight: 700;
}

a {
    color: inherit;
    text-decoration: inherit;
}
.link {
    text-decoration-line: underline;
    font-weight: 700;
}

.w-16 {
    width: 4rem;
}

.h-16 {
    height: 4rem;
}

.h1 {
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-right {
    text-align: right;
}

.mr-4 {
    margin-right: 1rem;
}

#label-monthly {
    margin-left: 10px;
}

.label {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FF7A00;
    transition: .4s;
    border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color:#FF7A00;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
.gap-8 {
    gap: 2rem;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid {
    display: grid;
}

.mt-8 {
    margin-top: 2rem;
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgba(26, 21, 18, 0.1), 0 1px 2px 0 rgba(26, 21, 18, 0.06);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.p-12 {
    padding: 3rem;
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 245 242 / var(--tw-bg-opacity));
}

.rounded-xl {
    border-radius: 0.75rem;
}

.items-start {
    align-items: flex-start;
}

.font-extrabold {
    font-weight: 800;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mb-16 {
    margin-bottom: 4rem;
}

sup {
    top: -0.5em;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.button-secondary {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .1875rem;
    font-weight: 700;
    --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, .1), 0 2px 4px -1px rgba(26, 21, 18, 0.06);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    cursor: pointer;
    font-size: .96rem;
    padding-top: .45em;
    padding-bottom: .45em;
    --tw-bg-opacity: 1;
    background-color: rgb(61 56 53 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ml-2 {
    margin-left: 0.5rem;
}

.text-primary {
    --tw-text-opacity: 1;
    color: rgb(252 87 47 / var(--tw-text-opacity));
}

.fill-current {
    fill: currentColor;
}

.w-5 {
    width: 1.25rem;
}

.h-5 {
    height: 1.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(252 87 47 / var(--tw-bg-opacity));
}

.rounded-sm {
    border-radius: 0.125rem;
}

.mt-1 {
    margin-top: 0.25rem;
}
