.header {
  margin-top: 1.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.topbar {
  margin-top: 1.25rem;
  margin-left: 1.2rem;
  margin-right: 1rem;
}

.navbar {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.topbar-brand {
  color: inherit;
  text-decoration: inherit;
  display: flex;
  align-items: center;
}

.logo {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  width: 40px;
  height: 40px;
}

.postsheet {
  margin-left: 0.5rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 900;
  color: black;
}
