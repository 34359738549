.top-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.menu-icon {
  display: none;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.burger-menu {
  display: none;
}

.header-menu {
}

.header-menu-align {
  align-items: flex-end;
}

.menu-option {
  color: inherit;
  text-decoration: inherit;
  border-radius: 0.125rem;
  padding-left: 0.89rem;
  padding-right: 0.89rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 700;
  color: black;
}

.menu-option:hover {
  background-color: #ec5800;
  color: white;
}

@media (max-width: 767px) {
  .md\:hidden {
    display: none;
  }
}

@media (max-width: 767px) {
  .md\:inline {
    display: inline;
  }
}
