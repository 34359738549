/*

!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
Este arquivo é o mesmo CSS original porém com todos os @apply substituidos pelos seus respectivos estilos.
o @apply aplicava algumas configurações padroes de estilo que vem com o tailwind, mas eles já estão todos substituidos.
!!!!!!!!!!!!!!!!!!
As variaveis --tw provavelmente não estão funcionando, porque também são do tailwind. Precisam ser recriadas em novas váriaveis ou algo do tipo




@tailwind base;
@tailwind components;
@tailwind utilities;*/

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 44 41 / var(--tw-bg-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.font-extrabold {
  font-weight: 800;
}

.text-3xl {
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
}

.text-2xl {
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
}

.text-xl {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.text-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
}

.font-bold {
  font-weight: 700;
}

.underline {
  text-decoration-line: underline;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(252 87 47 / var(--tw-text-opacity));
}

.-ml-1 {
  margin-left: -0.25rem /* -4px */;
}

.mr-1 {
  margin-right: 0.25rem /* 4px */;
}

.ml-1 {
  margin-left: 0.25rem /* 4px */;
}

.-mr-1 {
  margin-right: -0.25rem /* -4px */;
}

.inline-flex {
  display: inline-flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.px-4 {
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}

.rounded-tiny {
  border-radius: 0.1875rem /* 3px */;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.cursor-pointer {
  cursor: pointer;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-px {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-px {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 152 149 / var(--tw-bg-opacity));
}

.bg-gray-750 {
  --tw-bg-opacity: 1;
  background-color: rgb(61 56 53 / var(--tw-bg-opacity));
}

.mr-2 {
  margin-right: 0.5rem /* 8px */;
}

.ml-2 {
  margin-left: 0.5rem /* 8px */;
}

.mb-10 {
  margin-bottom: 2.5rem /* 40px */;
}

.p-8 {
  padding: 2rem /* 32px */;
}

.rounded-md {
  border-radius: 0.375rem /* 6px */;
}

.border-4 {
  border-width: 4px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(247 245 242 / var(--tw-border-opacity));
}

.inline-block {
  display: inline-block;
}

.px-3 {
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
}

.py-2 {
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}

.rounded-sm {
  border-radius: 0.125rem /* 2px */;
}

.w-8 {
  width: 2rem /* 32px */;
}

.h-8 {
  height: 2rem /* 32px */;
}

.mt-2 {
  margin-top: 0.5rem /* 8px */;
}

.rounded {
  border-radius: 0.25rem /* 4px */;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 242 / var(--tw-bg-opacity));
}

.border-none {
  border-style: none;
}

.shadow-inner-sm {
  --tw-shadow: inset 0 1px 4px 0 rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: inset 0 1px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(127 123 120 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(185 182 179 / var(--tw-text-opacity));
}

.pr-4 {
  padding-right: 1rem /* 16px */;
}

.pl-2 {
  padding-left: 0.5rem /* 8px */;
}

.pr-2 {
  padding-right: 0.5rem /* 8px */;
}

.border-gray-150 {
  --tw-border-opacity: 1;
  border-color: rgb(234 229 226 / var(--tw-border-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(49 44 41 / var(--tw-text-opacity));
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-125 {
  --tw-border-opacity: 1;
  border-color: rgb(240 237 234 / var(--tw-border-opacity));
}
.border-t-2 {
  border-top-width: 2px;
}

.px-2 {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
}

.py-1 {
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
}

.rounded-full {
  border-radius: 9999px;
}

.text-xs {
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.ml-5 {
  margin-left: 1.25rem /* 20px */;
}

.ml-10 {
  margin-left: 2.5rem /* 40px */;
}

.leading-tight {
  line-height: 1.25;
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(49 44 41 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

html {
  font-size: 15px;
}

em {
  background-image: linear-gradient(#fedf32, #fedf32);
  background-size: 100% 6px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

.confined {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
}

.h1 {
  font-weight: 800;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
}

.h2 {
  font-weight: 800;
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
}

.h3 {
  font-weight: 800;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.h4 {
  font-weight: 800;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
}

.link {
  font-weight: 700;
  text-decoration-line: underline;
}

.link:hover {
  --tw-text-opacity: 1;
  color: rgb(252 87 47 / var(--tw-text-opacity));
}

.link-simple {
  font-weight: 700;
}

.link-simple:hover {
  color: rgb(252 87 47 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.link .icon-left {
  margin-left: -0.25rem /* -4px */;
  margin-right: 0.25rem /* 4px */;
  width: 1.2rem;
  height: 1.2rem;
}

.link .icon-right {
  margin-left: 0.25rem /* 4px */;
  margin-right: -0.25rem /* -4px */;
  width: 1.2rem;
  height: 1.2rem;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-radius: 0.1875rem /* 3px */;
  font-weight: 700;
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  font-size: 0.96rem;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
}

.button-primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-radius: 0.1875rem /* 3px */;
  font-weight: 700;
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  font-size: 0.96rem;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  --tw-bg-opacity: 1;
  background-color: rgb(252 87 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.button-primary:hover {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.button-primary:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 152 149 / var(--tw-bg-opacity));
}

.button-secondary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-radius: 0.1875rem /* 3px */;
  font-weight: 700;
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  font-size: 0.96rem;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  --tw-bg-opacity: 1;
  background-color: rgb(61 56 53 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.button-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 87 47 / var(--tw-bg-opacity));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.button-primary .icon-left,
.button-secondary .icon-left {
  margin-left: -0.25rem;
  margin-right: 0.5rem /* 8px */;
  width: 1.4rem;
  height: 1.4rem;
}

.button-primary .icon-right,
.button-secondary .icon-right {
  margin-left: 0.5rem /* 8px */;
  margin-right: -0.25rem /* -4px */;
  width: 1.4rem;
  height: 1.4rem;
}

.panel {
  margin-bottom: 2.5rem /* 40px */;
  padding: 2rem /* 32px */;
  border-radius: 0.375rem /* 6px */;
  border-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(247 245 242 / var(--tw-border-opacity));
}

.error {
  display: inline-block;
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  border-radius: 0.125rem /* 2px */;

  background-color: #fde9e2;
}

.form-field-separator {
  width: 2rem /* 32px */;
  height: 2rem /* 32px */;
}

.form-field .form-field-label {
  font-weight: 700;
  font-size: 0.94rem;
}

.form-field .form-field-value {
  margin-top: 0.5rem /* 8px */;
}

.input-text {
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  border-radius: 0.25rem /* 4px */;
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 242 / var(--tw-bg-opacity));
  border-style: none;
  --tw-shadow: inset 0 1px 4px 0 rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: inset 0 1px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.input-text:read-only {
  --tw-text-opacity: 1;
  color: rgb(127 123 120 / var(--tw-text-opacity));
}

.input-text::placeholder {
  --tw-text-opacity: 1;
  color: rgb(185 182 179 / var(--tw-text-opacity));
}

.input-select {
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  border-radius: 0.25rem /* 4px */;
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 242 / var(--tw-bg-opacity));
  --tw-shadow: inset 0 1px 4px 0 rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: inset 0 1px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

table.nice td {
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-right: 1rem /* 16px */;
  padding-left: 0.5rem /* 8px */;
  font-size: 0.95rem;
}

table.nice td:last-child {
  padding-right: 0.5rem /* 8px */;
}

table.nice tbody tr:hover td {
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 242 / var(--tw-bg-opacity));
}

table.nice thead td {
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(234 229 226 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(234 229 226 / var(--tw-border-opacity));
  text-transform: uppercase;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(49 44 41 / var(--tw-text-opacity));
  font-size: 0.8rem;
}

table.nice tbody td {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(240 237 234 / var(--tw-border-opacity));
}

table.nice tfoot td {
  border-top-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(240 237 234 / var(--tw-border-opacity));
  font-weight: 700;
  white-space: nowrap;
  font-size: 0.94rem;
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.state {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
}

.state-initializing {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fce7c6;
  color: #4c3726;
}

.state-pending {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fce7c6;
  color: #4c3726;
}
.state-scheduled {

  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #e5fc7d;
  color: #21304c;
}
.state-paused {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fce7c6;
  color: #4c3726;
}
.state-completed_with_fail {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fcd1d0;
  color: #4c2120;
}

.state-running {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #c1f4fc;
  color: #21444c;
}

.state-cancelled {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fcd1d0;
  color: #4c2120;
}

.state-completed {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #d0f0c0;
  color: #405020;
}

.state-failed {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #fcd1d0;
  color: #4c2120;
}

.state-succeeded {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
  border-radius: 9999px;
  font-weight: 700;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  background-color: #d0f0c0;
  color: #405020;
}

hr {
  --tw-border-opacity: 1;
  border-color: rgb(247 245 242 / var(--tw-border-opacity));
  border-top-width: 2px;
}

.h1.mb-auto,
.h2.mb-auto,
.h3.mb-auto {
  margin-bottom: 0.4em;
}

p.mb-auto {
  margin-bottom: 1.5em;
}

ul.indent-sm,
ol.indent-sm {
  margin-left: 1.25rem /* 20px */;
}

ul.indent,
ol.indent {
  margin-left: 2.5rem /* 40px */;
}

.link-track-stats-hover {
  display: none;
}

td.link-tracking-cell:hover .link-track-stats {
  display: none;
}

td.link-tracking-cell:hover .link-track-stats-hover {
  display: inline;
}

.link-tracking-url {
  /* position: relative; */
  max-width: 100px;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::before,
[data-tooltip]::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #d8d5d2;
  position: absolute;
  top: -0.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 75ms transform;
  transform-origin: bottom center;
  background-color: #ffffff;
}

[data-tooltip][data-tooltip-position='right']::before,
[data-tooltip][data-tooltip-position='right']::after {
  top: 50%;
  left: 100%;
  /* transform: translateX(0) translateY(-50%) scale(var(--scale)); */
}

[data-tooltip][data-tooltip-position='right']::before {
  transform: translateX(var(--arrow-size)) translateY(-50%) scale(var(--scale));
}

[data-tooltip]:before {
  --translate-y: calc(-100% - var(--arrow-size));

  content: attr(data-tooltip);
  color: #312c29;
  font-size: 12px;
  width: max-content;
  max-width: 250px;
  padding: 0.5rem;
  white-space: pre-line;
  text-overflow: ellipsis;
  border-width: 4px;
}

[data-tooltip]:hover::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: '';
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

[data-tooltip][data-tooltip-position='right']:hover::after {
  border: var(--arrow-size) solid transparent;
  border-right-color: var(--tooltip-color);
  transform-origin: right center;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  --scale: 1;
}

.move-right {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.move-left {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div:has(> .panel.enable-overflow) {
  overflow-y: visible;
  overflow-x: auto;
}
