.confined {
    margin-left: auto;
    margin-right: auto;
    max-width: 1150px;
}

.h1 {
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mb-24 {
    margin-bottom: 6rem;
}

.items-end {
    align-items: flex-end;
}

.flex {
    display: flex;
}

.w-12 {
    width: 3rem;
}

.mr-4 {
    margin-right: 1rem;
}

img, video {
    max-width: 100%;
    height: auto;
}

img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}

.h2 {
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 2rem;
}

.gap-8 {
    gap: 2rem;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid {
    display: grid;
}

.p-6 {
    padding: 1.5rem;
}

.border-gray-125 {
    --tw-border-opacity: 1;
    border-color: rgb(240 237 234 / var(--tw-border-opacity));
}

.border-2 {
    border-width: 2px;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.flex-col {
    flex-direction: column;
}

.inline-flex {
    display: inline-flex;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 21 18 / var(--tw-bg-opacity));
}

.justify-between {
    justify-content: space-between;
}

.font-bold {
    font-weight: 700;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(127 123 120 / var(--tw-text-opacity));
}

.link-simple {
    font-weight: 700;
}

.w-16 {
    width: 4rem;
}

.h-16 {
    height: 4rem;
}

.w-20 {
    width: 5rem;
}

.h-8 {
    height: 2rem;
}

.uppercase {
    text-transform: uppercase;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.items-center {
    align-items: center;
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(49 44 41 / var(--tw-text-opacity));
}

.font-semibold {
    font-weight: 600;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 152 149 / var(--tw-text-opacity));
}

.fill-current {
    fill: currentColor;
}

.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.ml-16 {
    margin-left: 4rem;
}

.pt-5 {
    padding-top: 1.25rem;
}

.top-0 {
    top: 0px;
}

.h3 {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.list-decimal {
    list-style-type: decimal;
}

.ml-10 {
    margin-left: 2.5rem;
}

.mb-20 {
    margin-bottom: 5rem;
}

.h1.mb-auto, .h2.mb-auto, .h3.mb-auto {
    margin-bottom: .4em;
}

.mb-auto {
    margin-bottom: auto;
}

.mt-4 {
    margin-top: 1rem;
}

.shadow-semi-big {
    --tw-shadow: 0 0 25px -4px rgba(26, 21, 18, 0.18);
    --tw-shadow-colored: 0 0 25px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.overflow-hidden {
    overflow: hidden;
}
