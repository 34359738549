@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::selection {
    @apply bg-gray-800;
    @apply text-white;
  }

  html {
    font-size: 15px;
  }

  em {
    background-image: linear-gradient(#fedf32, #fedf32);
    background-size: 100% 6px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }
}

@layer components {
  .confined {
    @apply mx-auto;
    max-width: 1150px;
  }

  .h1 {
    @apply font-extrabold;
    @apply text-3xl;
  }

  .h2 {
    @apply font-extrabold;
    @apply text-2xl;
  }

  .h3 {
    @apply font-extrabold;
    @apply text-xl;
  }

  .h4 {
    @apply font-extrabold;
    @apply text-lg;
  }

  .link {
    @apply font-bold;
    @apply underline;
  }

  .link:hover {
    @apply text-primary;
  }

  .link-simple {
    @apply font-bold;
  }

  .link-simple:hover {
    @apply text-primary;
    @apply underline;
  }

  .link .icon-left {
    @apply -ml-1;
    @apply mr-1;
    width: 1.2rem;
    height: 1.2rem;
  }

  .link .icon-right {
    @apply ml-1;
    @apply -mr-1;
    width: 1.2rem;
    height: 1.2rem;
  }

  .button {
    @apply inline-flex;
    @apply justify-center;
    @apply items-center;
    @apply px-4;
    @apply rounded-tiny;
    @apply font-bold;
    @apply shadow-md;
    @apply cursor-pointer;
    font-size: 0.96rem;
    padding-top: 0.45em;
    padding-bottom: 0.45em;
  }

  .button-primary {
    @apply button;
    @apply bg-primary;
    @apply text-white;
  }

  .button-primary:hover {
    @apply transform;
    @apply -translate-y-px;
  }

  .button-primary:disabled {
    @apply bg-gray-400;
  }

  .button-secondary {
    @apply button;
    @apply bg-gray-750;
    @apply text-white;
  }

  .button-secondary:hover {
    @apply bg-primary;
    @apply transform;
    @apply -translate-y-px;
  }

  .button-primary .icon-left,
  .button-secondary .icon-left {
    @apply -ml-1;
    @apply mr-2;
    width: 1.4rem;
    height: 1.4rem;
  }

  .button-primary .icon-right,
  .button-secondary .icon-right {
    @apply ml-2;
    @apply -mr-1;
    width: 1.4rem;
    height: 1.4rem;
  }

  .panel {
    @apply mb-10;
    @apply p-8;
    @apply rounded-md;
    @apply border-4;
    @apply border-gray-100;
  }

  .error {
    @apply inline-block;
    @apply px-3;
    @apply py-2;
    @apply rounded-sm;
    background-color: #fde9e2;
  }

  .form-field-separator {
    @apply w-8;
    @apply h-8;
  }

  .form-field .form-field-label {
    @apply font-bold;
    font-size: 0.94rem;
  }

  .form-field .form-field-value {
    @apply mt-2;
  }

  .input-text {
    @apply px-3;
    @apply py-2;
    @apply rounded;
    @apply bg-gray-100;
    @apply border-none;
    @apply shadow-inner-sm;
  }

  .input-text:read-only {
    @apply text-gray-500;
  }

  .input-text::placeholder {
    @apply text-gray-300;
  }

  .input-select {
    @apply px-3;
    @apply py-2;
    @apply rounded;
    @apply bg-gray-100;
    @apply shadow-inner-sm;
  }

  table.nice td {
    @apply py-2;
    @apply pr-4;
    @apply pl-2;
    font-size: 0.95rem;
  }

  table.nice td:last-child {
    @apply pr-2;
  }

  table.nice tbody tr:hover td {
    @apply bg-gray-100;
  }

  table.nice thead td {
    @apply border-b-2;
    @apply border-gray-150;
    @apply font-bold;
    @apply uppercase;
    @apply whitespace-nowrap;
    @apply text-gray-800;
    font-size: 0.8rem;
  }

  table.nice tbody td {
    @apply border-b;
    @apply border-gray-125;
  }

  table.nice tfoot td {
    @apply border-t-2;
    @apply border-gray-125;
    @apply font-bold;
    @apply whitespace-nowrap;
    font-size: 0.94rem;
  }

  .modal {
    display: none;
  }

  .modal.is-open {
    display: block;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .state {
    @apply px-2;
    @apply py-1;
    @apply rounded-full;
    @apply font-bold;
    @apply text-xs;
    @apply tracking-tight;
    @apply uppercase;
  }

  .state-initializing {
    @apply state;
    background-color: #fce7c6;
    color: #4c3726;
  }

  .state-pending {
    @apply state;
    background-color: #fce7c6;
    color: #4c3726;
  }

  .state-paused {
    @apply state;
    background-color: #fce7c6;
    color: #4c3726;
  }
  .state-completed_with_fail {
    @apply state;
    background-color: #fcd1d0;
    color: #4c2120;
  }
  .state-running {
    @apply state;
    background-color: #c1f4fc;
    color: #21444c;
  }
  .state-scheduled {
    @apply state;
    background-color: #e5fc7d;
    color: #21304c;
  }
  .state-cancelled {
    @apply state;
    background-color: #fcd1d0;
    color: #4c2120;
  }

  .state-completed {
    @apply state;
    background-color: #d0f0c0;
    color: #405020;
  }

  .state-failed {
    @apply state;
    background-color: #fcd1d0;
    color: #4c2120;
  }

  .state-succeeded {
    @apply state;
    background-color: #d0f0c0;
    color: #405020;
  }

  hr {
    @apply border-gray-100;
    @apply border-t-2;
  }
}

@layer utilities {
  .h1.mb-auto,
  .h2.mb-auto,
  .h3.mb-auto {
    margin-bottom: 0.4em;
  }

  p.mb-auto {
    margin-bottom: 1.5em;
  }

  ul.indent-sm,
  ol.indent-sm {
    @apply ml-5;
  }

  ul.indent,
  ol.indent {
    @apply ml-10;
  }

  .link-track-stats-hover {
    display: none;
  }

  td.link-tracking-cell:hover .link-track-stats {
    display: none;
  }

  td.link-tracking-cell:hover .link-track-stats-hover {
    display: inline;
  }

  .link-tracking-url {
    /* position: relative; */
    max-width: 100px;
  }

  [data-tooltip] {
    position: relative;
  }

  [data-tooltip]::before,
  [data-tooltip]::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #d8d5d2;
    position: absolute;
    top: -0.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0))
      scale(var(--scale));
    transition: 75ms transform;
    transform-origin: bottom center;
    background-color: #ffffff;
  }

  [data-tooltip][data-tooltip-position='right']::before,
  [data-tooltip][data-tooltip-position='right']::after {
    top: 50%;
    left: 100%;
    /* transform: translateX(0) translateY(-50%) scale(var(--scale)); */
  }

  [data-tooltip][data-tooltip-position='right']::before {
    transform: translateX(var(--arrow-size)) translateY(-50%)
      scale(var(--scale));
  }

  [data-tooltip]:before {
    --translate-y: calc(-100% - var(--arrow-size));

    content: attr(data-tooltip);
    color: #312c29;
    font-size: 12px;
    width: max-content;
    max-width: 250px;
    padding: 0.5rem;
    white-space: pre-line;
    text-overflow: ellipsis;
    @apply border-4;
  }

  [data-tooltip]:hover::after {
    --translate-y: calc(-1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
  }

  [data-tooltip][data-tooltip-position='right']:hover::after {
    border: var(--arrow-size) solid transparent;
    border-right-color: var(--tooltip-color);
    transform-origin: right center;
  }

  [data-tooltip]:hover::before,
  [data-tooltip]:hover::after {
    --scale: 1;
  }

  @variants hover {
    .move-right {
      @apply transform;
      @apply translate-x-px;
    }

    .move-left {
      @apply transform;
      @apply -translate-x-px;
    }
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  div:has(> .panel.enable-overflow) {
    overflow-y: visible;
    overflow-x: auto;
  }
}
