.ql-editor {
  min-height: 15rem;
}

a {
  color: blue;
  text-decoration: underline;
}

code {
  color: blue;
  text-decoration: underline;
}