.content-html * {
  all: revert;
}

.content-html h1 {
  font-size: 2em;
}

.content-html h2 {
  font-size: 1.5em;
}

.content-html h3 {
  font-size: 1.17em;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*REACT SVG */
.max-w-lg {
  max-width: 32rem /* 512px */;
}

.my-32 {
  margin-top: 8rem /* 128px */;
  margin-bottom: 8rem /* 128px */;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.fill-current {
  fill: currentColor;
}

.text-gray-150 {
  --tw-text-opacity: 1;
  color: rgb(234 229 226 / var(--tw-text-opacity));
}

@media (max-width: 767px) {
  .md\:my-16 {
    margin-top: 4rem /* 64px */;
    margin-bottom: 4rem /* 64px */;
  }
}

@media (min-width: 480px) {
  .iframe-container {
    position: relative;
    width: 85%;
    padding-bottom: 50.25%;
    height: 0;
  }
}

.page-container {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 15px;
  line-height: inherit;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #d8d5d2;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  margin-top: 6rem;
  margin-bottom: 5rem;
  flex-grow: 1;
  margin-left: 2rem;
  margin-right: 2rem;
}
