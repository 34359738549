footer {
  --tw-bg-opacity: 1;
  background-color: rgb(26 21 18 / var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 2rem;
  padding-right: 2rem;
}

.footer-title {
}

.footer-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
}

.left-footer {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(127 123 120 / var(--tw-text-opacity));
}

.link-simple {
  color: inherit;
  text-decoration: inherit;
  font-weight: 700;
}

.wh-16 {
  color: inherit;
  text-decoration: inherit;
  height: 4rem;
  width: 4rem;
}

.footer-right {
  display: flex;
}

.title {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(127 123 120 / var(--tw-text-opacity));
}

.mt-1 {
  margin-top: 0.25rem;
}

.footer-right-option {
  color: inherit;
  text-decoration: inherit;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

.w20-h8 {
  height: 2rem;
  width: 5rem;
}

.icon {
  display: block;
  vertical-align: middle;
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  fill: currentColor;
}

.contact {
  color: inherit;
  text-decoration: inherit;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}
