/*!!!!!!!!!!!!!!! REDUZIR CLASSES !!!!!*/

.confined {
  margin: 0;
  max-width: 1150px;
}

.title-how-it-works {
  margin: 0;
  font-weight: 800;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: black;
  text-transform: none;
}

.h1 {
  font-weight: 800;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.btn {
  display: block;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  padding-top: 0.375rem /* 6px */;
  padding-bottom: 0.375rem /* 6px */;
  border-radius: 0.25rem /* 4px */;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
  transition-duration: 250ms;
  width: 100%;
  height: auto;
  border: none;
  text-align: left;
  color: black;
  white-space: nowrap; /* Evita a quebra de linha */

}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 33, 29, 0.911);
}

.selected-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(73 69 66 / var(--tw-bg-opacity));
  border: none;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.mb-2 {
  margin-bottom: 0.5rem /* 8px */;
}

.mt-2 {
  margin-top: 0.5rem /* 8px */;
}

.px-4 {
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}
.py-1\.5 {
  padding-top: 0.375rem /* 6px */;
  padding-bottom: 0.375rem /* 6px */;
}

.block {
  display: block;
}

.rounded {
}

.text-lg {
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ml-4 {
  margin-left: 1rem /* 16px */;
}

.mr-1 {
  margin-right: 0.5rem /* 4px */;
}

.mr-4 {
  margin-right: 1rem /* 16px */;
}

.mt-6 {
  margin-top: 1.5rem /* 24px */;
}

.mb-12 {
  margin-bottom: 3rem /* 48px */;
}

.pl-4 {
  padding-left: 1rem /* 16px */;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(73 69 66 / var(--tw-bg-opacity));
}

.border-l-4 {
  border-left-width: 4px;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(252 87 47 );
}

.hidden {
  display: none;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(73 69 66 / var(--tw-text-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 242 / var(--tw-bg-opacity));
}

.font-semibold {
  font-weight: 600;
}

.text-xl {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 152 149 / var(--tw-text-opacity));
}

.font-semibold {
  font-weight: 600;
}

.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .lg\:flex-col {
    flex-direction: column;
  }
}

@media (max-width: 1023px) {
  .lg\:text-center {
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .lg\:hidden {
    display: none;
  }
}

@media (max-width: 1023px) {
  .lg\:mt-20 {
    margin-top: 5rem /* 80px */;
  }
}

.rounded-md {
  border-radius: 0.375rem /* 6px */;
}

.shadow-big {
  --tw-shadow: 0 0 30px -4px rgba(26, 21, 18, 0.5);
  --tw-shadow-colored: 0 0 30px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
