.line {
  display: block;
  margin: 2rem auto;
  width: 100%;
}

.whole-main-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
  flex-grow: 1;
  margin-left: 2rem;
  margin-right: 2rem;
}

/* FIRST-SECTION */
.first-inside-main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.container-effortlessly {
  margin-top: 5rem;
}

.effortlessly {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.25;
  max-width: 30rem;
  color: black;
}

.using {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(170 165 162 / var(--tw-text-opacity));
}

.googlesheets {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(49 44 41 / var(--tw-text-opacity));
}

.googlesheets-icon {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  margin-right: 0.5rem;
  height: 1.25rem;
}

.airtable-icon {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  margin-right: 0.5rem;
  height: 1.5rem;
}
.gotodashboard-div {
  margin-top: 2rem;
}

.gotodashboard {
  text-decoration: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.1875rem;
  font-weight: 700;
  /*ESSES TW SAO VARIAVEIS*/
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  font-size: 0.96rem;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  --tw-bg-opacity: 1;
  background-color: rgb(252 87 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.main-img {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  width: 32rem;
}
