.avatar {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 45px;
  height: 45px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.testimonial-card {
  margin: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 280px;
}

.text {
  margin: 0;
  border-radius: 1rem;
  border-bottom-left-radius: 0px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(240 237 234 / var(--tw-border-opacity));
  padding: 1.5rem;
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, 0.1),
    0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: black;
}

.image-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.testimonial-name {
  font-size: 0.9rem;
  font-weight: 700;
  color: black;
}

.link {
  text-decoration: inherit;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(26 21 18 / var(--tw-text-opacity));
}
