body {
  margin: 0;
  line-height: inherit;
}
*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #d8d5d2;
}

body {
  display: block;
  margin: 8px;
}
html {
  font-size: 15px;
}
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
a {
  color: inherit;
  text-decoration: inherit;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.container {
  width: 100%;
}
.gap-4 {
  gap: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid {
  display: grid;
}
element.style {
  max-width: 30rem;
}
.leading-tight {
  line-height: 1.25;
}
.font-extrabold {
  font-weight: 800;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
.button-primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.1875rem;
  font-weight: 700;
  --tw-shadow: 0 4px 6px -1px rgba(26, 21, 18, .1), 0 2px 4px -1px rgba(26, 21, 18, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  font-size: .96rem;
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  --tw-bg-opacity: 1;
  background-color: rgb(252 87 47 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
a {
  color: inherit;
  text-decoration: inherit;
}
.shadow-semi-big {
  --tw-shadow: 0 0 25px -4px rgba(26, 21, 18, 0.18);
  --tw-shadow-colored: 0 0 25px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.rounded-lg {
  border-radius: 0.5rem;
}
.overflow-hidden {
  overflow: hidden;
}
img, video {
  max-width: 100%;
  height: auto;
}
img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}
.duration-1000 {
  transition-duration: 1000ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 213 210 / var(--tw-bg-opacity));
}
.w-0 {
  width: 0px;
}
.h-1 {
  height: 0.25rem;
}
.postsheet {
  margin-left: 0.5rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 900;
  color: black;
}
