.ck-editor__editable {
    min-height: 300px;  
    overflow: hidden;   
  }

  a {
    color: blue;
    text-decoration: underline;
  }
  
  code {
    color: blue;
    text-decoration: underline;
  }